import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Box, Fab, Button, Typography } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import DraftsRoundedIcon from '@material-ui/icons/DraftsRounded';
import ContactsIcon from '@material-ui/icons/Contacts';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import { Container, Row, Col } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default function Contactus() {

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);


  const handleChange = (event) =>{
  const target = event.target;
  const value = target.value;
  const name = target.name;
  if(name==="name")
  {
    setName(value);
  }
  if(name==="email")
  {
    setEmail(value);
  }
  if(name==="phone")
  {
    setPhone(value);
  }
  if(name==="message")
  {
    setMessage(value);
  }
  
}
const handleClick = () => {
  setOpen(true);
};

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
   setOpen(false);
};
const handleSubmit = (event) => {
  event.preventDefault();

  let collection={};
    collection.name = name;
    collection.email = email;
    collection.mobile = phone;
    collection.message = message;
    collection.scode = process.env.GATSBY_API_KEY;

 fetch('https://apiv2.chennaihost.com/site/contactlaw', {
      method: "post",
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },

      //make sure to serialize your JSON body
      body: JSON.stringify(collection),
      })
      .then( (response) => { 
        
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        handleClick();
        
      });
 


};

return(
  <Layout>
    <Seo title="Contact Details" description="Contact Number, Email, Address and Google Map" />
    <ThemeProvider theme={theme}>
<Box style={{ position:'relative', bottom:40 }}>
<Container>
    <Row className="bg-white">
        <Col xs={12}>
        <div className="p-3 list-icon mt-4">
           <Typography variant="h4" style={{ color:'#453260' }} className="text-center" gutterBottom>CONTACT US</Typography>
           <Typography variant="h5" style={{ color:'#453260' }} className="text-center" gutterBottom>World Humanitarian Charitable Trust ( R )</Typography>   
        </div>
        </Col>

        <Col xs={12} md={6} lg={4} className="d-flex">
            <Box align="center" className="border shadow-sm rounded-top flex-fill" p={2}>
                <Box mb={2}>
                <Fab style={{ backgroundColor:'green', color:'#fff' }}>
                <ContactsIcon />
              </Fab>
              </Box>
                <Typography variant="body1" style={{ color:'#453260' }}><b>Dr.B.R. Ambedkar College of Law</b></Typography>
                <Typography variant="body2" paragraph className="text-center">Affiliated to Karnataka State Law University</Typography>
                <Typography variant="body2" paragraph className="text-center" style={{ color:'#453260' }}><b>Prof: C.Ramamurthy M.A.,LL.B.,M.L.,M.Phil.,Ph.D.,</b><br/> Chairman & Principal</Typography>
                <Typography variant="body2" paragraph className="text-center">No.2, 5th Main Road, Srirampuram,<br/> Bangalore – 560021</Typography>
              
            </Box>
        </Col>

        <Col xs={12} md={6} lg={4} className="d-flex">
            <Box align="center" className="border shadow-sm rounded-top flex-fill" p={2}>
                <Box mb={2}>
                <Fab style={{ backgroundColor:'green', color:'#fff' }}>
                <PhoneIcon />
              </Fab>
              </Box>
                <Typography variant="body2" paragraph className="text-center" style={{ color:'#453260' }}><b>Phone: </b> <a href="tel:+918023525584" rel="noopener noreferrer" target="_blank" className="text-dark">080 23525584</a> / <a href="tel:+918023525585" rel="noopener noreferrer" target="_blank" className="text-dark">080 23525585</a></Typography>
                <Typography variant="body2" paragraph className="text-center" style={{ color:'#453260' }}><b>Mobile:</b> <a href="tel:7829956616" rel="noopener noreferrer" target="_blank" className="text-dark">7829956616</a></Typography>

                <Box mb={2}>
                <Fab style={{ backgroundColor:'green', color:'#fff' }}>
                <DraftsRoundedIcon />
              </Fab>
              </Box>
              <Typography variant="body2" paragraph style={{ color:'#453260' }} className="text-center"><b>Email:</b> <a href="mailto:cr.drbrambedkarcollegeoflaw@gmail.com" rel="noopener noreferrer" target="_blank" className="text-dark">cr.drbrambedkarcollegeoflaw@gmail.com</a></Typography>

            </Box>
        </Col>

        <Col xs={12} md={6} lg={4} className="d-flex">
            <Box align="center" className="border shadow-sm rounded-top flex-fill" p={2}>

            <Box mb={3}>
                <Fab style={{ backgroundColor:'green', color:'#fff' }}>
                <RoomIcon />
              </Fab>
              </Box>

            <Box pb={2}>
                <a href="https://www.google.com/maps/place/Dr.B.R.+Ambedkar+College+of+Law/@12.9886727,77.5663356,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae16220909c485:0x1341d895c1efa3ed!8m2!3d12.9886727!4d77.5685243" rel="noopener noreferrer" target="_blank">
                <Button
                    variant="contained"
                    color="Primary"
                    endIcon={<DoubleArrowIcon />}
                >
                    Reach Us Via google Map
                </Button>
                </a>
                </Box>
            </Box>
        </Col>
        
        <Row className="bg-light justify-content-center mt-5">
        <Col xs={12} lg={8}>
            <Box p={2}>
                <Typography variant="h4" gutterBottom className="text-center mt-3">Send Enquiry</Typography>

<Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
          
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}>
          <SnackbarContent
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" style={{display: 'flex', alignItems: 'center',}}>
           Form submitted successfully!. 
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon style={{fontSize: 20,}} />
        </IconButton>,
      ]}
     
    />
        </Snackbar>


  
           <ValidatorForm  onSubmit={handleSubmit} autoComplete="off">
<TextValidator 
          id="name"
          name="name"
          label="Your Name" 
          onChange={handleChange}
          value={name}
          validators={['required']}
          errorMessages={['this field is required']}
          variant="outlined"
          fullWidth
          margin="normal" />

<TextValidator 
          id="phone"
          name="phone"
          label="Mobile" 
          onChange={handleChange}
          value={phone}
          validators={['required']}
          errorMessages={['this field is required']}
          variant="outlined"
          fullWidth
          margin="normal" />

          <TextValidator 
          id="email"
          name="email"
          label="Email" 
          onChange={handleChange}
          value={email}
          validators={['required']}
          errorMessages={['this field is required', 'email is not valid']}
          variant="outlined"
          fullWidth
          margin="normal" />

          <TextValidator 
          id="message"
          label="Message" 
          onChange={handleChange}
          name="message"
          value={message}
          multiline
          rows="4"
          validators={['required']}
          errorMessages={['this field is required']}
          variant="outlined"
          fullWidth
          margin="normal" />
          
           
        <Box pt={4} pb={3}>
        <Button variant="contained" color='Primary' size="large" type="submit" endIcon={<SendIcon/>}>Submit</Button>
        </Box>
         </ValidatorForm>
                
            </Box>
        </Col>
        </Row>

    </Row>
</Container>
</Box>

</ThemeProvider>
  </Layout>
);
}
